import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();
  private loaderDisabled: boolean = false;

  constructor() { }

  show() {
    if(this.loaderDisabled) return;
    else {
      setTimeout(() => {
        this._loading.next(true);
      });
    }
  }

  hide() {
    this._loading.next(false);
  }

  disableLoad(): void {
    this.loaderDisabled = true;
  }

  enableLoad(): void {
    this.loaderDisabled = false;
  }
}
