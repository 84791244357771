import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private headersApp_Json: HttpHeaders;
  private rememberMeKey = 'rememberMe';
  isFirstCall: boolean = true;

  private logged = new ReplaySubject<boolean>(1);
  isLogged = this.logged.asObservable();
  constructor(
    private http: HttpClient,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.headersApp_Json = new HttpHeaders().set('Content-Type', 'application/json');
  }

  login(loginRequest: any): Observable<any> {
    return this.http.post(environment.baseUrl + 'user/login', loginRequest, { headers: this.headersApp_Json });
  }

  getUserInfo(): any {
    return this.parseJwt(localStorage.getItem('currentUser'));
  }

  checkOTP(OTPRequest: any): Observable<any> {
    return this.http.post(environment.baseUrl + 'user/loginotp', OTPRequest, { headers: this.headersApp_Json });
  }

  async checkToken(): Promise<boolean> {
    if (localStorage.getItem('refreshToken') && localStorage.getItem('rememberMe')) {
      this.isFirstCall = false;
      //restart timer for next refresh
      if (this.checkcurrentTokenValidity()) {
        return true;
      } else {
        return await this.refreshToken();
      }
    } else {
      if (this.checkcurrentTokenValidity()) return true;
      return false;
    }
  }

  /**
   * Check if token is available and the token expiration date
   * @returns true if token is valid, false otherwise
   */
  checkcurrentTokenValidity(): boolean {

    if (!localStorage.getItem('currentUser')) return false;
    if (!localStorage.getItem('tokenExpiration')) return false;

    let currentDate: Date = new Date(new Date().toISOString());
    let tokenExpirationDate: Date = new Date(localStorage.getItem('tokenExpiration'));

    if (tokenExpirationDate <= currentDate) {
      return false;
    } else {
      return true;
    }
  }


  /** 
   * refresh token start automatically every hour
   * @returns a Promise<boolean> returns true if the token is correcly refreshed
   *          false otherwise
   */
  async refreshToken(skip: boolean = false): Promise<boolean> {
    if (!this.isFirstCall && localStorage.getItem('currentUser') && !skip) return true;
    let refreshToken = localStorage.getItem('refreshToken');
    //disable user screen loading for the refresh of the bearer token
    try {
      this.loadingService.disableLoad();
      let res: any = await this.http.get(environment.baseUrl + 'User/RefreshAccessToken?refreshToken=' + refreshToken, { headers: this.headersApp_Json }).toPromise();
      this.loadingService.enableLoad();
      if (res.success) {
        localStorage.setItem('refreshToken', res.data.refreshToken);
        localStorage.setItem('currentUser', res.data.accessToken);
        //restart timeout for next refresh token
        this.startRefreshTokenTimeout();
        return true;
      } else false;
    } catch (err) {
      this.loadingService.enableLoad();
      console.error(err);
      return false;
    }
  }

  startRefreshTokenTimeout(): void {
    setTimeout(async () => {
      //call api to refresh token
      this.refreshToken(true);
    }, (environment.tokenDuration - 1) * 60 * 1000);
    // subtract one minute to anticipate token expiration
  }

  setRememberMe(rememberMe: boolean): void {
    localStorage.setItem(this.rememberMeKey, rememberMe.toString());
  }

  forgotPassword(email): Observable<any> {
    return this.http.post(environment.baseUrl + 'User/ResetPassword', email, { headers: this.headersApp_Json });
  }

  /*resetPswd(emailForReset): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("email", emailForReset);
    return this.http.get(environment.baseUrl + 'reset-password', {
      params: queryParams,
      responseType: 'json'
    });
  }*/

  checkRoleRoute(route: string) {
    return true;
  }

  resetPswd(request): Observable<any> { // sostenio-be.it/reset-password?token=blablabla&email=admin@email.com
    return this.http.post(environment.baseUrl + 'reset-password', request, { headers: this.headersApp_Json });
  }

  parseJwt(token) {
    if (!token) return;
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

}
