import { FeaturedProductsModule } from './featured-products/featured-products.module';
import { Routes } from '@angular/router';
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { CanActivateRouteGuard } from "./can-activate-route.guard";
import { roleGuard } from './Role.guard';
import { environment } from '../environments/environment';

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: environment.home,
    pathMatch: "full",
  },
  {
    path: "pages",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/pages/pages.module").then((x) => x.PagesModule),
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "account",
        loadChildren: () =>
          import("./accounts/accounts.module").then((x) => x.AccountsModule),
        canActivate: [CanActivateRouteGuard],
      },
      // {
      //   path: "listini",
      //   loadChildren: () =>
      //       import("./pricelist/pricelist.module").then((x) => x.PricelistModule),
      //   canActivate: [CanActivateRouteGuard, roleGuard],
      // },
      {
        path: "agenti",
        loadChildren: () =>
          import("./agents/agents.module").then((x) => x.AgentsModule),
        canActivate: [CanActivateRouteGuard],
      },
      {
        path: "promozioni",
        loadChildren: () =>
          import("./promotions/promotions.module").then(
            (x) => x.PromotionsModule
          ),
        canActivate: [CanActivateRouteGuard],
      },
      {
        path: "forum",
        loadChildren: () =>
          import("./forum/forum.module").then(
            (x) => x.ForumModule
          ),
        canActivate: [CanActivateRouteGuard],
      },
      {
        path: "catalogo-premi",
        loadChildren: () =>
          import("./gift-catalogue/gift-catalogue.module").then(
            (x) => x.GiftCatalogueModule
          ),
        canActivate: [CanActivateRouteGuard],
      },
      {
        path: "prodotti-evidenza",
        loadChildren: () =>
          import("./featured-products/featured-products.module").then(
            (x) => x.FeaturedProductsModule
          ),
        canActivate: [CanActivateRouteGuard, roleGuard],
      },
      {
        path: "categorie",
        loadChildren: () =>
          import("./categories/categories.module").then(
            (x) => x.CategoriesModule
          ),
        canActivate: [CanActivateRouteGuard],
      },
      {
        path: "prodotti-correlati",
        loadChildren: () =>
          import("./related-products/related-products.module").then(
            (x) => x.RelatedProductsModule
          ),
        canActivate: [CanActivateRouteGuard],
      },
      {
        path: "notifiche-push",
        loadChildren: () =>
          import("./push-notifications/push-notifications.module").then(
            (x) => x.PushNotificationsModule
          ),
        canActivate: [CanActivateRouteGuard],
      },
      {
        path: "components",
        loadChildren: () =>
          import("./pages/components/components.module").then(
            (x) => x.ComponentsPageModule
          ),
      },
      {
        path: "prodotti",
        loadChildren: () =>
          import("./products/products.module").then(
            (x) => x.ProductsModule
          ),
        canActivate: [CanActivateRouteGuard],
      },
    ],
  },
  {
    path: "**",
    redirectTo: "/agenti/lista",
  },
];
