import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-action-table',
  templateUrl: './action-table.component.html',
  styleUrls: ['./action-table.component.css'],
})

export class ActionTableComponent implements OnInit {

  @Output() actionEmit: EventEmitter<string> = new EventEmitter<string>();
  values: any;
  instance: any;

  constructor(
      private router: Router
  ) { 
  }

  static componentInit(instance: ActionTableComponent, cell, values) {
    instance = instance;
    instance.values = values;
  }

  ngOnInit(): void {
  }

  // click event disabled by css class
  isDataDisabled(classList, email) {
    if(email === null){
      if(classList == 'create-app-user'){
        return classList+' true'
      }else{
        return classList+' false'
      }

    }else{
      if(classList == 'create-app-user'){
        return classList+' false'
      }else{
        return classList+' true'
      }
    }
  }

  routeTo(e: any) {
    if (e.link) {
      this.router.navigate([e.link]);
    } else if (e.emit != null && e.emit !== '') {
      this.actionEmit.emit(e.emit);
    }
  }

}
