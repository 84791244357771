<ng-template #pickSearch let-d="dismiss">
    <div class="modal-header justify-content-between">
        <h6 class="title mt-2 mb-3">Aggiungi {{itemsName}}</h6>
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
    </div>
    <div class="modal-body pt-5 pb-5">
        <div class="input-search">
            <input id="inputSearch" type="text" class="w-100" (input)="search()"
                placeholder="Cerca per codice o descrizione" [(ngModel)]="searchTerm" />
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path id="Icon_awesome-search" data-name="Icon awesome-search"
                    d="M15.582,13.66l-3.076-3.076a.74.74,0,0,0-.525-.216h-.5a6.415,6.415,0,1,0-1.111,1.111v.5a.74.74,0,0,0,.216.525l3.076,3.076a.737.737,0,0,0,1.046,0l.873-.873a.744.744,0,0,0,0-1.049ZM6.418,10.368a3.95,3.95,0,1,1,3.95-3.95A3.947,3.947,0,0,1,6.418,10.368Z"
                    fill="#d2cdcd" />
            </svg>
        </div>
        <div *ngIf="itemsFound.length != 0 && !isLoadingTableData" class="items-found-container">
            <div *ngFor="let item of itemsFound" class="d-flex item-found" (click)="itemClicked(item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="15.797" height="15.8" viewBox="0 0 15.797 15.8">
                    <path id="Icon_awesome-search" data-name="Icon awesome-search"
                        d="M15.582,13.66l-3.076-3.076a.74.74,0,0,0-.525-.216h-.5a6.415,6.415,0,1,0-1.111,1.111v.5a.74.74,0,0,0,.216.525l3.076,3.076a.737.737,0,0,0,1.046,0l.873-.873a.744.744,0,0,0,0-1.049ZM6.418,10.368a3.95,3.95,0,1,1,3.95-3.95A3.947,3.947,0,0,1,6.418,10.368Z"
                        fill="#d2cdcd" />
                </svg>
                <p [innerHtml]="(item.name) | highlighter:searchTerm" class="col-12 my-2"> </p>
            </div>
        </div>
        <div *ngIf="isLoadingTableData">
            <div *ngFor="let i of toIterator(10)" class="skeleton-text my-2"></div>
        </div>
    </div>
</ng-template>