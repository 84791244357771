import { Component } from "@angular/core";
import { AuthService } from './services/auth.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "app";
  constructor(
    private authService: AuthService
  ) {
    //if expired remove token and auth guard 
    //automatically refresh it, if possible
    if(this.authService.checkcurrentTokenValidity() == false) {
      localStorage.removeItem('currentUser');
    }
  }
}
