import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class roleGuard {

  //Map of routes granted for every role
  subRoutesGranted: Map<string, string[]> = new Map();

  constructor(
    public authService: AuthService,
    public router: Router
  ) {
    this.subRoutesGranted['CapoArea'] = ['agenti', 'account', 'prodotti'];
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let granted = false;

    let currentRole = this.authService.getUserInfo().role;

    //Admin has permissions for all paths
    if (currentRole == 'Admin') return true;

    if (!Array.isArray(currentRole)) false;
    //array checked
    currentRole.forEach(r => {
      let pathsGranted: string[] = this.subRoutesGranted[r];
      if (pathsGranted && pathsGranted.indexOf(next.routeConfig.path) !== -1) granted = true;
    });

    return granted;
  }

}
