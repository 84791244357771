<div class="wrapper">
  <app-sidebar></app-sidebar>
  <div class="main-panel">
    <app-navbar></app-navbar>
    <div class="wrap-spinner d-flex align-items-center justify-content-center" *ngIf="loading$ | async">
      <div class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div [ngClass]="{'d-none': loading$ | async}">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>
