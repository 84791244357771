import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  private title = 'Nasti Dashboard';
  private showBackBtnSource = new BehaviorSubject<boolean>(false);
  showBackBtn$ = this.showBackBtnSource.asObservable();

  constructor() { }
  
  setTitle(title:string):void {
    this.title = title;
  }

  getTitle():string {
    return this.title;
  }

  /**
   * Show the back button
   * @param show true show the back button
   */
  setshowBackBtn(show: boolean) {
    this.showBackBtnSource.next(show);
  }

}
