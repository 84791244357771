<footer class="footer">
  <div class="container-fluid">
    <!--<nav>
      <ul>
        <li><a href="https://www.creative-tim.com"> Creative Tim </a></li>
        <li><a href="http://presentation.creative-tim.com"> About Us </a></li>
        <li><a href="http://blog.creative-tim.com"> Blog </a></li>
      </ul>
    </nav>-->
    <div class="copyright" id="copyright">
      &copy; {{ test | date: "yyyy" }}, Designed by
      <a href="https://www.dotitsrl.it/">Dotit</a>. Coded
      by
      <a href="https://www.dotitsrl.it/" target="_blank">Dotit</a>.
    </div>
  </div>
</footer>
