<a *ngFor="let e of values" [attr.id]="e.btnId" [ngClass]="isDataDisabled(e.classList, e.emailAgent)" [title]="e.title"
    (click)="routeTo(e)" href="javascript:void(0)" placement="top" ngbTooltip={{e.title}}>
    <span>
        <i *ngIf="!e.stackIcon && e.iconClass" [class]="e.iconClass" aria-hidden="true"></i>
        <span *ngIf="e.stackIcon && e.iconClassList" [class]="e.stackClass">
            <i *ngFor="let ic of e.iconClassList" [class]="ic"></i>
        </span>
        <span class="text_action_btn">
            {{e.text}}
        </span>
    </span>
</a>