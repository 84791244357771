import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { share } from "rxjs/operators";
import { LoadingService } from "../services/loading.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { MessageFormatterService } from "../services/messageFormatter.service";

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private loader: LoadingService,
    private toastrService: ToastrService,
    private messageFormatter: MessageFormatterService,
    private router: Router
  ) { }

  isLoginPage(): boolean {
    return this.router.url === "/pages/login";
  }
  // tslint:disable-next-line:indent
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser: any = localStorage.getItem("currentUser")
      ? localStorage.getItem("currentUser")
      : "";
    if (currentUser) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + currentUser),
      });
    }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });

    // spinner
    this.loader.show();

    return this.makeRequest(request, next);
  }

  makeRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const req: Observable<HttpEvent<any>> = next.handle(request).pipe(
      share(),
      tap((event) => { }),
      catchError((err) => {
        if (err.status === 401) {
          this.sessionExpired(err);
        } else if (err.status === 422) {
          if (err && err.error && err.error.message) {
            this.toastrService.error(
              this.messageFormatter.getFormattedMessage(err, true),
              this.messageFormatter.getErrorTitle(),
              {
                positionClass: "toast-bottom-left",
                tapToDismiss: true,
                enableHtml: true,
              }
            );
          }
        } else {
          this.toastrService.error(err.error.Message || err.error.message, "Errore!", {
            positionClass: "toast-bottom-left",
          });
        }
        return throwError(() => (err));
      }),
      finalize(() => {
        this.loader.hide();
      })
    );

    return req;
  }

  sessionExpired(err) {
    localStorage.removeItem("currentUser");
    localStorage.removeItem('rememberMe');
    if (err instanceof HttpErrorResponse) {
      try {
        if (!this.isLoginPage()) {
          this.toastrService.error("Sessione scaduta", err.statusText, {
            positionClass: "toast-bottom-right",
          });
          setTimeout(
            () =>
            (window.location.href =
              window.location.origin + "/pages/login"),
            3000
          );
        }
      } catch (e) {
        this.toastrService.error("An error occurred", "", {
          positionClass: "toast-bottom-right",
        });
        setTimeout(
          () =>
          (window.location.href =
            window.location.origin + "/pages/login"),
          3000
        );
      }
    }
  }
}
