import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'pick-item',
  templateUrl: './pick-item.component.html',
  styleUrls: ['./pick-item.component.css']
})

export class PickItemComponent implements OnInit {

  @Input() itemsName: string;
  @Input() apiGetItems: string;
  @Input() triggerModalPickObj: Observable<void>;

  @Output() itemPicked: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("pickSearch") inputSearchModal: ElementRef;
  searchTerm = '';
  itemsFound = [];
  
  options: any;
  isLoadingTableData: boolean = false;
  subscriptions: Subscription[] = [];
  
  constructor(
    private modalService: NgbModal,
    private http: HttpClient
  ) { }

  ngOnInit() {
    let HTTPheaders = ({
      'Content-Type': 'application/json',
    });
    this.options = ({
      headers: HTTPheaders,
    });
    
    const sub = this.triggerModalPickObj.subscribe(() => this.openModalPickItem());
    this.subscriptions.push(sub);
  }

  /**
   * Emit chosen item
   */
  itemClicked(itemChosen) {
    this.itemPicked.emit(itemChosen);
    this.modalService.dismissAll();
    this.searchTerm = '';
  }

  search(): void {
    if (this.searchTerm.length < 3) {
      this.itemsFound = [];
      this.isLoadingTableData = false;
      return;
    }

    this.itemsFound = [];
    this.isLoadingTableData = true;

    let getItems = this.http.get(environment.baseUrl + this.apiGetItems + this.searchTerm, this.options);

    const sub = getItems.subscribe({
        next: (res: any) => {
          if (this.searchTerm.length >= 3) {
            this.itemsFound = res.data.list;
          }
          this.isLoadingTableData = false;
        },
        complete: () => {
          this.isLoadingTableData = false;
        },
      });

    this.subscriptions.push(sub);
  }
  
  openModalPickItem(): void {
    this.modalService.open(this.inputSearchModal, {
      size: "xl",
      backdrop: "static",
      windowClass: "modal-input-search",
      centered: false,
      backdropClass: 'modal-search',
      keyboard: true,
      modalDialogClass:'modal-input-search__dialog'
    });
    this.searchTerm = "";
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  toIterator(n: number): number[] {
    return Array(n).fill(0);
  }
}
