import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NgbdModalBasic } from "./modal/modal.component";
import { ImageUploadComponent } from "./image-upload/image-upload.component";
import { FileInputComponent } from "./file-input/file-input.component";
import { PictureUploadComponent } from './picture-upload/picture-upload.component';
import { ActionTableComponent } from './action-table/action-table.component';
import { InstantSearchComponent } from "./instant-search/instant-search.component";
import { HighlighterPipe } from "../pipes/highlight.pipe";
import { PickItemComponent } from "./pick-item/pick-item.component";
import { FileRetrievePipe } from "../pipes/file-retrieve.pipe";


@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, FormsModule, NgbModule, ReactiveFormsModule],
  declarations: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    NgbdModalBasic,
    ImageUploadComponent,
    FileInputComponent,
    PictureUploadComponent,
    ActionTableComponent,
    InstantSearchComponent,
    PickItemComponent,
    FileRetrievePipe,
    HighlighterPipe,
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    NgbdModalBasic,
    ImageUploadComponent,
    PictureUploadComponent,
    FileInputComponent,
    InstantSearchComponent,
    PickItemComponent,
    FileRetrievePipe
  ],
  providers: [

  ],
})
export class ComponentsModule { }
