import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';

type field = {
  name: string,
  key: string,
  colDim: string, //dimension of the col in the table,
  type: string
}

@Component({
  selector: 'instant-search',
  templateUrl: './instant-search.component.html',
  styleUrls: ['./instant-search.component.css']
})

export class InstantSearchComponent implements OnInit, OnDestroy {

  @Input() itemsName: string;
  @Input() apiGetItems: string;
  @Input() fields: field[];
  @Input() triggerModalOpening: Observable<void>;

  @Output() itemsToAdd: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("inputSearch") inputSearchModal: NgbActiveModal;
  searchTerm = '';
  itemsFound = [];
  itemsAdded: any[] = [];
  options: any;
  isLoadingTableData: boolean = false;
  notResults: boolean = false;
  subscriptions: Subscription[] = [];
  modal: any;

  constructor(
    private modalService: NgbModal,
    private http: HttpClient
  ) { }

  ngOnInit() {
    let HTTPheaders = ({
      'Content-Type': 'application/json',
    });
    this.options = ({
      headers: HTTPheaders,
    });

    const sub = this.triggerModalOpening.subscribe(() => this.openModalAddItems());
    this.subscriptions.push(sub);
  }

  /**
   * Emit items added to the parent component
   */
  addItems() {
    this.itemsToAdd.emit(this.itemsAdded);
    this.modal.dismiss()
    this.itemsAdded = [];
  }

  search(): void {
    if (this.searchTerm.length < 3) {
      this.itemsFound = [];
      this.isLoadingTableData = false;
      return;
    }

    this.itemsFound = [];
    this.isLoadingTableData = true;

    let getItems = this.http.get(environment.baseUrl + this.apiGetItems + this.searchTerm, this.options);

    const sub = getItems.subscribe({
      next: (res: any) => {
        if (this.searchTerm.length >= 3) {
          this.itemsFound = res.data.list;
          if (this.itemsFound.length == 0) {
            this.notResults = true
          } else {
            this.notResults = false
          }
        }
        this.isLoadingTableData = false;
      },
      complete: () => {
        this.isLoadingTableData = false;
      },
    });

    this.subscriptions.push(sub);
  }

  /**
   * If the item to add is already present
   * it doesn't add its to the list
   * @param itemToAdd
   */
  addItemFound(itemToAdd) {
    let notPresent = true;
    this.itemsAdded.findIndex((item) => {
      if (item.id === itemToAdd.id) notPresent = false;
    });
    if (notPresent) {
      itemToAdd['quantity'] = 1;
      let item = {};
      this.fields.forEach((f: field) => {
        item[f.key] = itemToAdd[f.key];
      });
      this.itemsAdded.push(item);
      itemToAdd["present"] = true;
    }

    this.itemsFound = [];
    this.searchTerm = "";
  }

  removeItemAdded(itemToRemove) {
    this.itemsAdded.forEach((item, index) => {
      if (item.id === itemToRemove.id)
        this.itemsAdded.splice(index, 1);
    });
  }

  openModalAddItems(): void {
    this.modal = this.modalService.open(this.inputSearchModal, {
      size: "xl",
      backdrop: "static",
      windowClass: "modal-input-search",
      centered: false,
      backdropClass: 'modal-search',
      keyboard: true,
      modalDialogClass: 'modal-input-search__dialog'
    });
    this.searchTerm = "";
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  toIterator(n: number): number[] {
    return Array(n).fill(0);
  }

}
